import React from "react";
import {Link,useLocation} from 'react-router-dom';

function SideNav(){
  const location = useLocation();
  const { pathname } = location;
return (
    <nav className="mt-2">
      
    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      
    <li className='nav-item'>
        <Link to='/admin'>
          <span className={`nav-link ${pathname === '/admin' ? 'active' : ''}`}>
            <i className="nav-icon fas fa-th"></i>
            <p>Dashboard</p>
          </span>
        </Link>
      </li>
      
      <li className='nav-item'>
        <Link to='/studentfees'>
          <span className={`nav-link ${pathname === '/studentfees' ? 'active' : ''}`}>
            <i className="nav-icon fas fa-dollar"></i>
            <p>Student Payment</p>
          </span>
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/studenthistory'>
          <span className={`nav-link ${pathname === '/studenthistory' ? 'active' : ''}`}>
            <i className="nav-icon fas fa-list"></i>
            <p>Payment History</p>
          </span>
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/expensemanager'>
          <span className={`nav-link ${pathname === '/expensemanager' ? 'active' : ''}`}>
            <i className="nav-icon fas fa-dollar"></i>
            <p>Expense Manager</p>
          </span>
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/students'>
          <span className={`nav-link ${pathname === '/students' ? 'active' : ''}`}>
            <i className="nav-icon fas fa-users"></i>
            <p>Students</p>
          </span>
        </Link>
      </li>
      
      
    
    </ul>
  </nav>
);
}
export default SideNav;