import './App.css';
import Login from './components/Login';
import { BrowserRouter as Router, Route,Routes, Navigate} from 'react-router-dom';
import Adminpanel from './components/Adminpanel';
import Fees from './components/Fees';
import Studentfees from './components/Studentfees';
import Studenthistory from './components/Studenthistory';
import Expensemanager from './components/Expensemanager';
import Students from './components/Students';
function App() {
  return (
    <Router>
      
    <Routes>
    <Route path="/expensemanager" element={<Expensemanager/>} />
     <Route path="/studentfees" element={<Studentfees />} />
     <Route path="/students" element={<Students />} />
      <Route path="/fees" element={<Fees />} />
      <Route path="/logout" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<Adminpanel />} />
      <Route path="/studenthistory" element={<Studenthistory />} />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  </Router>
  );
}

export default App;
