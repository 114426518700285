import React,{useEffect,useState} from "react";
import { useNavigate } from 'react-router-dom';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
function Students(){
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [studdata, setStudData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchValue, setsearchValue] = useState('');

  const getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  // Get token from cookie
  let token = getCookie('token');
  let tokenID = getCookie('tokenID');
  
  useEffect(() => {
    // Fetch data from the API endpoint
   setLoading(true);
    fetch('https://api.sncoi.edu.ph/getstudentslist.php?token='+token+'&tokenID='+tokenID)
      .then((response) => response.json())
      .then((data) => setStudData(data))
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => {

        setLoading(false);
      });

     
  }, []);
  const handleSearchChange = (event) => {
    setsearchValue(event.target.value);
  };
  

  // Function to handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered data based on search query
  const filteredData = studdata.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

 
 
  useEffect(() => {
     
  
      // Get token from cookie
      let token = getCookie('token');
      let tokenID = getCookie('tokenID');
      if (token) {
          fetch('https://api.sncoi.edu.ph/index.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
            })
            .then(response => response.json())
            .then(data => {
             
              if(data==1){

              }else{
                  navigate('/login');
              }
              
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error (e.g., display error message)
            });
        
      }else{
          navigate('/login');
      }

    }, []);
         
    return(
        <div className="wrapper">
        <TopNav/>

<aside className="main-sidebar sidebar-dark-primary elevation-4">



<div className="sidebar vh-100">
  
  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
    <div className="image">
    <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
    </div>
    <div className="info">
      <p className="d-block text-light">Finance Admin</p>
    </div>
  </div>


  <SideNav/>

</div>  

</aside>
<div className="content-wrapper">
<section className="content">
  <div className="container-fluid">
     <div className="row">
     <input
        className="mt-2"
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearch}
      />
      <div className="col-12 pt-2" style={{ maxHeight:'600px', overflow: 'scroll' }}>

      <table className="table table-hover" id="table1">
        <tr>
        <th>ID number</th>
          <th>Name</th>
          <th>Course</th>
          <th>Year Level</th>
        </tr>
     
        { loading? (<div>Loading..</div>):
                  
                filteredData.map((item) => (
                  <>
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.course}</td>
                    <td>{item.level}</td>
                  </tr>
                 </>
                ))
              
                
              }
        </table>
      </div>
        
      </div>
     
  </div>
</section>
</div>
<aside className="control-sidebar control-sidebar-dark">

</aside>
<footer className="main-footer">
<strong>Copyright &copy; 2024 </strong>
All rights reserved.

</footer>
    </div>
    );
}
export default Students;