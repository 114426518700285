import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Line,Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import "../App.css";
import SideNav from './SideNav';
import TopNav from './TopNav';
function Adminpanel(){
    const navigate = useNavigate();
    const [sydata, setsyData] = useState([]);
    const [schoolYear, setSchoolyear] = useState('');
    const [collections, setCollections] = useState('');
    const [expenses, setExpenses] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isLoading2, setLoading2] = useState(true);
    
    const formatToPeso = (value) => {
      const formatter = new Intl.NumberFormat('en-PH', {
        style: 'currency',
        currency: 'PHP',
      });
      return formatter.format(value);
    };

    const handleSchoolyear=async(e)=>{
      setLoading(true);
      setLoading2(true);
      
        try {
          const response = await fetch('https://api.sncoi.edu.ph/getDatapersem.php?token='+token+"&tokenID="+tokenID+"&sem="+e.target.value, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
        
          });
        
          const data = await response.text();
          const datares=JSON.parse(data);
          
          setCollections(datares['totalcollection']);
          setExpenses(datares['totalexpenses']);
          setLoading(false);
          setLoading2(false);
        } catch (error) {
          console.error('Error sending:', error);
          
        } 
      setSchoolyear(e.target.value);
    };
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
    const token = getCookie('token');
    const tokenID = getCookie('tokenID');
    useEffect(() => {
      // Fetch data from the API endpoint
     
      fetch('https://api.sncoi.edu.ph/getCollections.php?token='+token+'&tokenID='+tokenID)
        .then((response) => response.json())
        .then((data) => setCollections(data))
        .catch((error) => console.error('Error fetching data:', error));
        setLoading(false);
    }, []);

    useEffect(() => {
      // Fetch data from the API endpoint
     
      fetch('https://api.sncoi.edu.ph/getExpenses.php?token='+token+'&tokenID='+tokenID)
        .then((response) => response.json())
        .then((data) => setExpenses(data))
        .catch((error) => console.error('Error fetching data:', error));
        setLoading2(false);
    }, []);

    useEffect(() => {
      // Fetch data from the API endpoint
     
      fetch('https://api.sncoi.edu.ph/getschoolyear.php?token='+token+'&tokenID='+tokenID)
        .then((response) => response.json())
        .then((data) => setsyData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const chartdata = {
      labels: ['Collections', 'Expenses'],
      datasets: [
        {
          label: 'Total Amount',
          data: [collections, expenses],
          fill: false,
          backgroundColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            
            
          ],
          borderColor: [
            'rgba(255, 99, 132, 0)',
            'rgba(54, 162, 235, 0)',
            
          ],
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
    useEffect(() => {
        // Get cookie by name
        
        if (token) {
            fetch('https://api.sncoi.edu.ph/index.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
              })
              .then(response => response.json())
              .then(data => {
               
                if(data==1){

                }else{
                    navigate('/login');
                }
                
              })
              .catch(error => {
                console.error('Error:', error);
                // Handle error (e.g., display error message)
              });
          
        }else{
            navigate('/login');
        }

      }, []);
      
      return(
        <div className="wrapper">
            <TopNav/>

    <aside class="main-sidebar sidebar-dark-primary elevation-4">
    

  
    <div class="sidebar vh-100">
      
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
        <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
        </div>
        <div class="info">
          <p class="d-block text-light">Finance Admin</p>
          
        </div>
      </div>

    
      <SideNav/>
    
    </div>  
    
  </aside>
   <div class="content-wrapper">
   <section class="content">
      <div class="container-fluid">
      <div class="row">
        <div className='col-12'> 
        <select name="schoolyear" onChange={handleSchoolyear} className="form-control col-5 mt-2"> 
                <option value="">select schoolyear</option>
                      {sydata.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.AC} - sem {item.sem} 
                        </option>
                         
                      ))}
                   
                </select>
        </div>
      
          <div class="col-12 col-sm-6 col-md-6 mt-3">
         
            <div class="info-box">
              <span class="info-box-icon bg-info elevation-1"><i class="fas fa-sign-in-alt"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Total Collections</span>
                <span class="info-box-number">
                 
                  <small>{isLoading? ("Loading.."):(formatToPeso(collections))}</small>
                </span>
              </div>
              
            </div>
           
          </div>
         
          <div class="col-12 col-sm-6 col-md-6 mt-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-sign-out-alt"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Total Expenses</span>
                <small>{isLoading2? ("Loading.."):(formatToPeso(expenses))}</small>
              </div>
              
            </div>
          
          </div>
       
        
        </div>
        <div class="row">
          <div class="card-body">
          <Bar data={chartdata} options={options} />
              
          </div>
        
        </div>
        
       
      </div>
    </section>
    </div>
  <aside class="control-sidebar control-sidebar-dark">
   
  </aside>
  <footer class="main-footer">
    <strong>Copyright &copy; 2024 </strong>
    All rights reserved.
    
  </footer>
        </div>
      );
}
export default Adminpanel;