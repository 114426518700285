import React,{useEffect,useState,Component} from "react";
import { useNavigate } from 'react-router-dom';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Swal from "sweetalert2";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
function Studenthistory(){
  const navigate = useNavigate();
  const [searching, setSearching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [historydata, sethistoryData] = useState([]);
  const [studdata, setStudData] = useState([]);
  const [sydata, setsyData] = useState([]);
  const [searchstudent, setSearchStud] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState([]);
  const [studID, setStudID] = useState('');
  const [studName, setStudName] = useState('');
  const [studLevel, setStudLevel] = useState('');
  const [courseID, setCourseID] = useState('');
  const [courseName, setCourseName] = useState('');
  const [schoolYear, setSchoolyear] = useState('');
  const [transactionID, setTransaction] = useState('');
  const [istransaction, setTransactionshow] = useState(false);
  
  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //const sanitizedValue = value.replace(/[^0-9. ]/g, '');

    // const normalizedValue = sanitizedValue.replace(/(\..*)\./g, '$1');
  setFormData(prevState => ({
    ...prevState,
    [name]: value,
  }));
  console.log(formData);
  };

  const handlesearchStud=(e)=>{
    setSearchStud(e.target.value);
  };
 
  const handleSchoolyear=(e)=>{
    setSchoolyear(e.target.value);
  };

  const getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  // Get token from cookie
  let token = getCookie('token');
  let tokenID = getCookie('tokenID');
  
  useEffect(() => {
    // Fetch data from the API endpoint
   
    fetch('https://api.sncoi.edu.ph/getschoolyear.php?token='+token+'&tokenID='+tokenID)
      .then((response) => response.json())
      .then((data) => setsyData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);





  
 function search(){
 
  const searchStud = async () => {
    setSearching(true);
    if(schoolYear==""){
      Swal.fire({
        title: "Opps..",
        text: "School year required before searching",
        icon: "warning"
      });
      setSearching(false);
      return;
    }else if(searchstudent==""){
      Swal.fire({
        title: "Opps..",
        text: "Student ID required before searching",
        icon: "warning"
      });
      setSearching(false);
      return;
    }else{

    
    try {
      const response = await fetch('https://api.sncoi.edu.ph/searchstudhistory.php?token='+token+'&tokenID='+tokenID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({searchstudent,schoolYear}),
      });
      
      const data = await response.text();
      console.log(data);
      const datares=JSON.parse(data);
      setSearching(false);
      setStudID(datares[0].id);
      setStudName(datares[0].name);
      setCourseID(datares[0].courseID);
      setStudLevel(datares[0].level);
      setCourseName(datares[0].course);
      console.log(datares);
      getFees(datares[0].courseID,datares[0].id,datares[0].level,schoolYear);
      
    } catch (error) {
      console.error('Error searching data:', error);
      
      console.log(error);
    }
  }
  };

  searchStud();
 }
 function getFees(courseID,studID,studYear,schoolYear){
  
  const getfeesStud = async () => {
    
    try {
      const response = await fetch('https://api.sncoi.edu.ph/getstudents_payment.php?token='+token+'&tokenID='+tokenID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({courseID,studID,studYear,schoolYear}),
      });
      
       const data = await response.text();
       //console.log(data);
      const datares=JSON.parse(data);
      sethistoryData(datares);
      
      setLoading(false);
    } catch (error) {
      console.error('Error getting data:', error);
      
      console.log(error);
    }
  };

  getfeesStud();
 }

 
 
 
  const handlesubmit= async (e)=>{
      e.preventDefault();
      
      if(studID==''){
        Swal.fire({
          title: "Opps..",
          text: "Student required",
          icon: "warning"
        });
        setSubmitting(false);
        return;
        }
      if(schoolYear==''){
        Swal.fire({
          title: "Opps..",
          text: "School year is required",
          icon: "error"
        });
        setSubmitting(false); 
        return;
        }
     
      const sendData = async () => {
        setSubmitting(true);
         
        try {
          const response = await fetch('https://api.sncoi.edu.ph/addpaymenthistory.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({formData,courseID,studLevel,studID,schoolYear,tokenID,token}),
          });
        
          const data = await response.text();
          console.log(data);
          let res=JSON.parse(data);
          if(res[0]==1){
            Swal.fire({
              title: "Nice!",
              text: "Amount has been saved",
              icon: "success"
            });
            setTransaction(res[2]);
            setTransactionshow(true);
            setSubmitting(false);
            search();
          }else{
            Swal.fire({
              title: "Opps..",
              text: "Something went wrong "+data,
              icon: "error"
            });
            setSubmitting(false);
          }
         
        } catch (error) {
          console.error('Error sending data:', error);
          setSubmitting(true);
          console.log(error);
        }
      };
  
      sendData();

    }
  
       const handleRemove = async (id) => {
         setSubmitting(true);
          console.log(id);
          
            try {
              const response = await fetch('https://api.sncoi.edu.ph/removepayment.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(id),
              });
            
              const data = await response.text();
              console.log(data);
              let res=JSON.parse(data);
              if(res[0]==1){
                Swal.fire({
                  title: "Deleted!",
                  text: "Record has been deleted.",
                  icon: "success"
                });
                setTransaction(res[2]);
                setTransactionshow(true);
                setSubmitting(false);
                search();
              }else{
                Swal.fire({
                  title: "Opps..",
                  text: "Something went wrong "+data,
                  icon: "error"
                });
                setSubmitting(false);
              }
              
            } catch (error) {
              console.error('Error sending data:', error);
              setSubmitting(true);
              console.log(error);
            }
          }
      
    
  
  useEffect(() => {
     
  
      // Get token from cookie
      let token = getCookie('token');
      let tokenID = getCookie('tokenID');
      if (token) {
          fetch('https://api.sncoi.edu.ph/index.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
            })
            .then(response => response.json())
            .then(data => {
             
              if(data==1){

              }else{
                  navigate('/login');
              }
              
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error (e.g., display error message)
            });
        
      }else{
          navigate('/login');
      }

    }, []);
         
    return(
        <div className="wrapper">
        <TopNav/>

<aside className="main-sidebar sidebar-dark-primary elevation-4">



<div className="sidebar vh-100">
  
  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
    <div className="image">
    <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
    </div>
    <div className="info">
      <p className="d-block text-light">Finance Admin</p>
    </div>
  </div>


  <SideNav/>

</div>  

</aside>
<div className="content-wrapper">
<section className="content">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 pt-2">
      <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 550 }}
    >
      <select name="schoolyear" onChange={handleSchoolyear} className="form-control col-5"> 
                <option value="">select schoolyear</option>
                      {sydata.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.AC} - sem {item.sem} 
                        </option>
                         
                      ))}
                   
                </select>
      <InputBase
        value={searchstudent}
        onChange={handlesearchStud}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Student by ID number"
        
        inputProps={{ 'aria-label': 'Search Student by ID number' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={search}>
        <SearchIcon />{searching? (<i className="fa fa-spinner fa-spin"></i>):""}
      </IconButton>
      
    </Paper>
      </div>    
    </div>
      <div className="row">
          <div className="col-lg-4 m-2">
            <div className="card">
              <div className="card-header">
                      <span>Student Information</span>
              </div>
              <div className="card-body">
              <label>Student ID:</label>
              <p>{studID}</p>
              <label>Student Name: </label>
              <p>{studName}</p>
              <label>Course: </label>
              <p>{courseName}</p>
              <label>Year Level: </label>       
              <p>{studLevel}</p> 
              </div>
              
              </div>        
          </div>
          <div className="col-lg-6 m-2">
            <div className="card">
              <div className="card-header">
              <span>Payment</span>   
              </div>
              <div className="card-body">
              <label>Amount</label>         
              <input type="text" name="amountopay" onChange={handleInputChange} className="form-control"/> 
              <label>OR Number</label>         
              <input type="text" name="ornumber" onChange={handleInputChange} className="form-control"/>
              <label>Add-on Type</label> 
              <select className="form-control"  name="addontype" onChange={handleInputChange}>
                        <option>select type</option>
                        <option value="1" key="1"> USC Membership</option>  
                        <option value="2" key="2"> Intramurals</option>  
                        <option value="3" key="3"> Foundation</option>
                        <option value="4" key="4"> Case study/Graduation</option>   
                      
                  </select> 
              <button className="btn btn-primary mt-2" onClick={handlesubmit}>Save</button>
              </div>
            

            </div>
              

            </div>
        </div>

        <div className="col-lg-12 mt-3">

        <label>Payment History</label>           
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="table table-hover">
                      <thead className="sticky-top">
                        <tr>
                          <th>Trasaction ID</th>
                          <th>OR #</th>
                          <th>Total Paid Amount</th>
                          <th>Date Created</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                      { loading ? (<div>No Student Selected..</div>):
                  
                          historydata.map((item) => (
                            <tr key={item.id}>
                              <td>{item.transaction_number}</td>
                              <td>{item.ornumber}</td>
                              <td>{item.totalamount}</td>
                              <td>{item.datecreated}</td>
                              <td><a target='_blank' className="btn btn-success" href={`https://api.sncoi.edu.ph/printtransact.php?token=${token}&tokenID=${tokenID}&transID=${item.transaction_number}&syID=${item.syID}&studID=${item.studID}`}>
                               View Receipt
                              </a>
                              <button className="btn btn-danger" onClick={() => handleRemove(item.transaction_number)}><i className="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          ))
                    
                      }
                      </tbody>
                </table>      
               
                
                
            </div>
            
        </div>
       
       
      
     
  </div>
</section>
</div>
<aside className="control-sidebar control-sidebar-dark">

</aside>
<footer className="main-footer">
<strong>Copyright &copy; 2024 </strong>
All rights reserved.

</footer>
    </div>
    );
}
export default Studenthistory;