import React,{useEffect,useState} from "react";
import { useNavigate } from 'react-router-dom';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Swal from "sweetalert2";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function Fees(){
    const navigate = useNavigate();
    const [fees, setFees] = useState([]);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showtype, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [coursedata, setCourseData] = useState([]);
    const [sydata, setsyData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    
    const [selectedCourses, setSelectedCourses] = useState([]);
    const options = [1,2,3,4]; // Levels
    const [selectedOptions, setSelectedOptions] = useState([]);
    const handlecourseChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedCourses(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      
    };
    const handleoptionChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedOptions(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      
    };
    const handleSelectAll = () => {
      if (selectedCourses.includes('all')) {
        const allCourseIds = coursedata.map(item => item.course_id);
        setSelectedCourses(allCourseIds);
      } else {
        setSelectedCourses([]);
      }
    };
    const handleoptionSelectAll = () => {
      if (selectedOptions.includes('all')) {
        const alllevel = options.map(item => item);
        setSelectedOptions(alllevel);
      } else {
        setSelectedOptions([]);
      }
    };

    useEffect(() => {
      if (selectedCourses.includes('all')) {
        const allCourseIds = coursedata.map(item => item.course_id);
        setSelectedCourses(allCourseIds);
      }
      
      
    }, [selectedCourses]);
    useEffect(() => {
     
      if (selectedOptions.includes('all')) {
        const all_level = options.map(item => item);
        setSelectedOptions(all_level);
      }
    
      
    }, [selectedOptions]);
    
    
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };
    // Get token from cookie
    let token = getCookie('token');
    let tokenID = getCookie('tokenID');
   
    const handleSearchChange = (e) => {
      const term = e.target.value;
      setSearchTerm(term);
      
      const newFilteredData = data.filter((item) => 
        item.fee_description && item.fee_description.toLowerCase().includes(term.toLowerCase())
      );
      if(term==''){
        getFees();
      }
      setData(term ? newFilteredData : data);
    };
  
    
    useEffect(() => {
      // Fetch data from the API endpoint
      fetch('https://api.sncoi.edu.ph/getschoolyear.php')
        .then((response) => response.json())
        .then((data) => setsyData(data))
        .catch((error) => console.error('Error fetching data:', error));
        console.log(sydata);
    }, []);

    useEffect(() => {
     setLoading(true);
      fetch('https://api.sncoi.edu.ph/getcourses.php?token='+{token}+'&tokenID='+tokenID)
        .then((response) => response.json())
        .then((data) => setCourseData(data))
        .catch((error) => console.error('Error fetching data:', error));
        setLoading(false);
        
    }, []);
        
   
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch('https://api.sncoi.edu.ph/get.php?token='+{token}+'&tokenID='+tokenID)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  
  function getFees(){
    
    fetch('https://api.sncoi.edu.ph/get.php?token='+{token}+'&tokenID='+tokenID)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFees({
            ...fees,
            [name]: value,    
        });

        
    };
    const handleStud=(e)=>{
      const { name, value } = e.target;
        
      setFees({
          ...fees,
          [name]: value,    
      });
    };
    const handleType=(e)=>{
      const { name, value } = e.target;
        
      setFees({
          ...fees,
          [name]: value,    
      });
      if(e.target.value==2){
        setShow(true);
      }else{
        setShow(false);
      }
    };
 

   
   
      const removeData = async (id) => {
        
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
        try {
          const response = await fetch('https://api.sncoi.edu.ph/dlt.php?token='+{token}+'&tokenID='+tokenID, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({"id":id}),
          });
  
          const data = await response.text();
          console.log(data);
          let res=JSON.parse(data);
          if(res[0]==1){
            Swal.fire({
              title: "Nice!",
              text: "Fee has been removed",
              icon: "success"
            });
            getFees();
           
          }else if(res[0]==0){
              
              Swal.fire({
                title: "Opps..",
                text: "Existing Fee",
                icon: "error"
              });
          }else{
            Swal.fire({
              title: "Opps..",
              text: "Something went wrong "+data,
              icon: "error"
            });
          }
         
        } catch (error) {
          console.error('Error logging in:', error);
         
        }
      };
      function isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
      }
      
    const handlesubmit= async (e)=>{
        e.preventDefault();
        setSending(true);
        if(fees.feeamount=='' || fees.feedesc==''){
          Swal.fire({
            title: "Opps..",
            text: "Fee Description and amount is required",
            icon: "error"
          });
        }else if(fees.feetype==''){
          Swal.fire({
            title: "Opps..",
            text: "Fee Type is required",
            icon: "error"
          });
        }else{
          if(!isNumeric(fees.feeamount)){
            Swal.fire({
              title: "Opps..",
              text: "Fee amount should be in number",
              icon: "error"
            });
            return;
          }
         
        const sendData = async () => {
          try {
            const response = await fetch('https://api.sncoi.edu.ph/addfee.php?token='+{token}+'&tokenID='+tokenID, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({fees,selectedOptions,selectedCourses}),
            });
            
            const data = await response.text();
            
            setSending(false);
            let res=JSON.parse(data);
            if(res[0]==1){
              Swal.fire({
                title: "Saved!",
                text: "Fee has been saved",
                icon: "success"
              });
              getFees();
              e.target.reset();
              setFees([]);
              setSelectedCourses([]);
              setSelectedOptions([]);
              setSending(false);
            }else if(res[0]==0){
                
                Swal.fire({
                  title: "Opps..",
                  text: "Existing Fee",
                  icon: "error"
                });
                setSending(false);
            }else{
              Swal.fire({
                title: "Opps..",
                text: "Something went wrong "+data,
                icon: "error"
              });
              setSending(false);
            }
           
          } catch (error) {
            console.error('Error:', error);
            setSending(false);
          }
        };
    
        sendData();

      }
    }

    useEffect(() => {
       
    
       
        if (token) {
            fetch('https://api.sncoi.edu.ph/index.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify( {tokenID,token} ) // Send tokenID in request body
              })
              .then(response => response.json())
              .then(data => {
               
                if(data==1){

                }else{
                    navigate('/login');
                }
                
              })
              .catch(error => {
                console.error('Error:', error);
                // Handle error (e.g., display error message)
              });
          
        }else{
            navigate('/login');
        }

      }, []);
      
     
    return(
        <div className="wrapper">
        <TopNav/>

<aside className="main-sidebar sidebar-dark-primary elevation-4">



<div className="sidebar vh-100">
  
  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
    <div className="image">
    <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
    </div>
    <div className="info">
      <p className="d-block text-light">Finance Admin</p>
    </div>
  </div>


  <SideNav/>

</div>  

</aside>
<div className="content-wrapper">
<section className="content">
  <div className="container-fluid">
     <div className="row">
        <div className="col-4  mt-3">
            <form onSubmit={handlesubmit} id="frm">
                <label>Enter Fee Description</label>
                <input type="text" name="feedesc" onChange={handleChange} className="form-control"/>
                <label>Enter Fee Amount</label>
                <input type="text" name="feeamount" onChange={handleChange}  className="form-control"/>
                <label>School Year</label>
                <select name="schoolyear" className="form-control" onChange={handleChange}> 
                <option value="">select schoolyear</option>
                      {sydata.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.AC} - sem {item.sem} 
                        </option>
                         
                      ))}
                   
                </select>
                <label>Fee Type</label>
                <select id="feetype" name="feetype" onChange={handleType} className="form-control">
                      <option key={0} value={0}>select type</option>
                      <option key={1} value={1}>Miscellaneous</option>
                      <option key={2} value={2}>Special Fee</option>
                      <option key={3} value={3}>Regular Fee</option>
                      <option key={4} value={4}>Tuition Fee per Unit</option>
                </select>
                <br/>
                {
                  showtype ? (
                  <div id="studcnt">
                  <label>Student ID</label>
                        <input type="text" name="studID" id="studID" onChange={handleStud} className="form-control" placeholder="enter studendt ID"/>
                  </div>
                  ):(<div id="coursecnt">
                  <label>For Course</label>
                  
                  <Select
                      labelId="courses"
                      id="courses"
                      multiple
                      value={selectedCourses}
                      onChange={handlecourseChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      className="form-control"
                    >
                       <MenuItem key={'all'} value={'all'} onClick={handleSelectAll}>
                        
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {coursedata.map((item) => (
                        <MenuItem key={item.course_id} value={item.course_id}>
                          <Checkbox checked={selectedCourses.includes(item.course_id)} />
                          <ListItemText primary={item.abrv} />
                        </MenuItem>
                      ))}
                    </Select>
                  
                  
                 
                  <label>For Level</label>
                  
                  <Select
                      labelId="levels"
                      id="levels"
                      multiple
                      value={selectedOptions}
                      onChange={handleoptionChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      className="form-control"
                    >
                       <MenuItem key={'all'} value={'all'} onClick={handleoptionSelectAll}>
                        
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {options.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={selectedOptions.includes(item)} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>

                  
                  </div>
                )
                }
                
                
                
                <button className="btn btn-primary mt-3" disabled={sending? true:false}>Save {sending?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
            </form>
          
        </div>
        <div className="col-8 mt-3">
        <input 
            type="text" 
            placeholder="Search by Name" 
            value={searchTerm} 
            onChange={handleSearchChange} 
          />
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          
          <table className="table table-hover" >
                <thead>
                    <tr>
                        <th>Fee Description</th>
                        <th>Amount</th>
                        <th>School Year</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody id='feedata'>
                {data.map((item) => (
                  <tr key={item.id}>
                   
                    <td>{item.fee_description}</td>
                    <td>{item.amount}</td>
                    <td>{item.schoolyear}</td>
                    <td><button className="btn btn-danger" onClick={() => removeData(item.id)}>
                  Remove
                </button></td>
                    {/* Display other data fields here */}
                  </tr>
                ))}
                </tbody>
                
            </table>
            </div>
        </div>
       
      </div>
     
  </div>
</section>
</div>
<aside className="control-sidebar control-sidebar-dark">

</aside>
<footer className="main-footer">
<strong>Copyright &copy; 2024 </strong>
All rights reserved.

</footer>
    </div>
    );
}
export default Fees;