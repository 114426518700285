import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import "../App.css";
import SideNav from './SideNav';
import TopNav from './TopNav';
function Expensemanager(){
    const navigate = useNavigate();
    const [sydata, setsyData] = useState([]);
    const [expensedata, setexpData] = useState([]);
    const [schoolYear, setSchoolyear] = useState('');
    const [showtype,setShowtype]=useState(true);
    const [expType, setExptype] = useState('');
    const [expDesc, setExpdesc] = useState('');
    const [expenseDescData, setExpensedescform] = useState('');
    const [expenseAmount, setExpenseAmount] = useState('');
    const [isSending, setSending]=useState(false);

   
    const handleSchoolyear=(e)=>{
      setSchoolyear(e.target.value);
    };
    
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };
    const handleshow=()=>{
        setShowtype(!showtype);
    }
    const handleexpDesc=(e)=>{
        setExpdesc(e.target.value);
    }
    const handleexpenseDesc=(e)=>{
        setExpensedescform(e.target.value);
    }
    const handleexpenseAmount=(e)=>{
      const { value } = e.target;
      const regex = /^[0-9.]*$/;
      if (regex.test(value)) {
        setExpenseAmount(e.target.value);
      } 
    }
    const handletype=(e)=>{
        setExptype(e.target.value);
    }
    // Get token from cookie
    const token = getCookie('token');
    const tokenID = getCookie('tokenID');

    const addtype=async()=>{
        if(expDesc==''){
            Swal.fire({
                title: "Opps",
                text: "Description is required",
                icon: "warning"
              }); 
        }else{

       
        try {
            const response = await fetch('https://api.sncoi.edu.ph/addType.php?token='+token+"&tokenID="+tokenID, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({expDesc}),
            });
           
            const data = await response.text();
            const datares=JSON.parse(data);
           if(datares[0]==1){
            Swal.fire({
                title: "Nice",
                text: "Expense type added",
                icon: "success"
              }); 
           }else{
            Swal.fire({
                title: "Opps",
                text: "Expense type already exist try another one",
                icon: "warning"
              }); 
           }
           getexpenseType();
            
          } catch (error) {
            console.error('Error sending:', error);
            
          } 
        }
    }

    const handleSave=async()=>{
        setSending(true);
        if(expenseDescData=='' || expenseAmount=='' || schoolYear=='' || expType==''){
            Swal.fire({
                title: "Opps",
                text: "All fields are required",
                icon: "warning"
              }); 
        }else{
           
            
        try {
            const response = await fetch('https://api.sncoi.edu.ph/addExpenses.php?token='+token+"&tokenID="+tokenID, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({expenseDescData,expenseAmount,schoolYear,expType}),
            });
             
            const data = await response.text();
            const datares=JSON.parse(data);
           
           if(datares[0]==1){
            Swal.fire({
                title: "Nice",
                text: "Expense added",
                icon: "success"
              }); 
              setSending(false);
           }else{
            Swal.fire({
                title: "Opps",
                text: "Expense  already exist try another one",
                icon: "warning"
              }); 
              setSending(false);
           }
          
            
          } catch (error) {
            console.error('Error sending:', error);
            setSending(false);
          } 
        }
    }
    useEffect(() => {
      // Fetch data from the API endpoint
     
      fetch('https://api.sncoi.edu.ph/getschoolyear.php?token='+token+'&tokenID='+tokenID)
        .then((response) => response.json())
        .then((data) => setsyData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }, []);

    useEffect(() => {
    
        fetch('https://api.sncoi.edu.ph/getexpenseType.php?token='+token+'&tokenID='+tokenID)
          .then((response) => response.json())
          .then((data) => setexpData(data))
          .catch((error) => console.error('Error fetching data:', error));
      }, []);
      function getexpenseType(){
        fetch('https://api.sncoi.edu.ph/getexpenseType.php?token='+token+'&tokenID='+tokenID)
          .then((response) => response.json())
          .then((data) => setexpData(data))
          .catch((error) => console.error('Error fetching data:', error));
      }
    useEffect(() => {
        // Get cookie by name
        
        if (token) {
            fetch('https://api.sncoi.edu.ph/index.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
              })
              .then(response => response.json())
              .then(data => {
               console.log(data);
                if(data==1){

                }else{
                    navigate('/login');
                }
                
              })
              .catch(error => {
                console.error('Error:', error);
                // Handle error (e.g., display error message)
              });
          
        }else{
            navigate('/login');
        }

      }, []);
      
      return(
        <div className="wrapper">
            <TopNav/>

    <aside class="main-sidebar sidebar-dark-primary elevation-4">
    

  
    <div class="sidebar vh-100">
      
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
        <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
        </div>
        <div class="info">
          <p class="d-block text-light">Finance Admin</p>
          
        </div>
      </div>

    
      <SideNav/>
    
    </div>  
    
  </aside>
   <div class="content-wrapper">
   <section class="content">
      <div class="container-fluid">
        <div class="row">
            <div className='col-6 card mt-2' >
                
                <div className='card-body'>
                    <label>Expense Description</label>
                    <input type="text" className='form-control' value={expenseDescData} onChange={handleexpenseDesc} name="expensedesc"/> 
                    <div className='row'>
                        <div className='col-lg-6 mt-2'>
                        <label>School year</label>   
                    <select name="schoolyear" onChange={handleSchoolyear} className="form-control  mt-2"> 
                            <option value="">select schoolyear</option>
                                {sydata.map((item) => (
                                    <option value={item.id} key={item.id}>
                                    {item.AC} - sem {item.sem} 
                                    </option>
                                    
                                ))}
                            
                                </select>
                        </div>
                        <div className='col-lg-6 mt-2'>
                        <button className='btn  float-right' onClick={handleshow}><i className='fa fa-cog'></i></button>
                        <label>Expense Type</label>   
                            <select name="exptype" onChange={handletype} className="form-control mt-2"> 
                            <option value="">select type</option>
                                {expensedata.map((item) => (
                                    <option value={item.id} key={item.id}>
                                    {item.description}
                                    </option>
                                    
                                ))}
                            
                                </select>
                        </div>
                    
                            
                    </div>
                                       
                <label>Expense Amount</label>
                <input type="text" className='form-control' value={expenseAmount} onChange={handleexpenseAmount} name="expenseamount"/>  
                <button className='btn btn-primary mt-2' disabled={isSending? true: false} onClick={handleSave}>Save {isSending? (<i className='fa fa-spinner fa-spin'></i>):''}</button>      
                </div>
            </div>

            {
            !showtype && (
            <div className='col-6 card mt-2' >
                <div className='card-body'>
                    <label>Expense Type</label>
                    <input type="text" className='form-control' onChange={handleexpDesc} value={expDesc} name="expensetype"/> 
                    
                    <button className='btn btn-primary mt-2' onClick={addtype}>Add Type</button>      
                 </div>
            </div>)
            } 
            <div className='col-12'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Expense Type</th>
                            <th>School Year</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </section>
    </div>
  <aside class="control-sidebar control-sidebar-dark">
   
  </aside>
  <footer class="main-footer">
    <strong>Copyright &copy; 2024 </strong>
    All rights reserved.
    
  </footer>
        </div>
      );
}
export default Expensemanager;