import React,{useEffect,useState,Component} from "react";
import { useNavigate } from 'react-router-dom';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Swal from "sweetalert2";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { Description } from "@mui/icons-material";
function Studentfees(){
  const navigate = useNavigate();
  const [searching, setSearching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [studdata, setStudData] = useState([]);
  const [sydata, setsyData] = useState([]);
  const [searchstudent, setSearchStud] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState([]);
  const [studID, setStudID] = useState('');
  const [studName, setStudName] = useState('');
  const [studCourse, setStudCourse] = useState('');
  const [studYear, setStudYear] = useState('');
  const [unitsNumber, setunitsNumber] = useState('');
  const [schoolYear, setSchoolyear] = useState('');
  const [transactionID, setTransaction] = useState('');
//payment
const [tuition, setTuition] = useState('');
const [registration, setRegistration] = useState('');
const [misc, setMisc] = useState('');
const [advisory, setAdvisory] = useState('');
const [crimlab, setCrimlab] = useState('');
const [sasfee, setSasfee] = useState('');
const [enhancement, setEnhancement] = useState('');
const [usc, setUsc] = useState('');

  const [istransaction, setTransactionshow] = useState(false);
  const [courseID, setCourseID] = useState('');
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  setFormData(prevState => ({
    ...prevState,
    [name]: value,
  }));
  console.log(formData);
  };

  const handlesearchStud=(e)=>{
    setSearchStud(e.target.value);
  };
  const handleUnits=(e)=>{
    setunitsNumber(e.target.value);
  };
  const handleSchoolyear=(e)=>{
    setSchoolyear(e.target.value);
  };

  const getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  // Get token from cookie
  let token = getCookie('token');
  let tokenID = getCookie('tokenID');
  
  useEffect(() => {
    // Fetch data from the API endpoint
   
    fetch('https://api.sncoi.edu.ph/getschoolyear.php?token='+token+'&tokenID='+tokenID)
      .then((response) => response.json())
      .then((data) => setsyData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);


// useEffect(() => {
//   //setinitial();
// }, [data]);

// function setinitial(){
  
//   let initialFormData = {};
//   if(data){
//     data.forEach(item => {
//       initialFormData[item.id] = item.amount || ''; // Set initial value or empty string if not provided
//     });
//     setFormData(initialFormData);
//     console.log(initialFormData);
//   }
  
// }

  
 function search(){
 
  const searchStud = async () => {
    setSearching(true);
    setTransaction([]);
    setTransactionshow(false);
    setSubmitting(false);
    if(schoolYear==""){
      Swal.fire({
        title: "Opps..",
        text: "School year required before searching",
        icon: "warning"
      });
      setSearching(false);
      return;
    }else if(searchstudent==""){
      Swal.fire({
        title: "Opps..",
        text: "Student ID required before searching",
        icon: "warning"
      });
      setSearching(false);
      return;
    }else{

    
    try {
      const response = await fetch('https://api.sncoi.edu.ph/searchstud.php?token='+{token}+'&tokenID='+tokenID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({searchstudent,schoolYear}),
      });
      
      const data = await response.text();
    
      const datares=JSON.parse(data);
      
      setSearching(false);
      setStudID(datares[0].id ? datares[0].id : ''); 
      setStudName(datares[0].name ? datares[0].name : '');
      setStudCourse(datares[0].course ? datares[0].course : ''); 
      setStudYear(datares[0].level ? datares[0].level : ''); 
      setunitsNumber(datares[0].units ? datares[0].units : '');
      //console.log(datares);
      getFees(datares[0].courseID,datares[0].id,datares[0].level,schoolYear);
      
    } catch (error) {
      console.error('Error searching data:', error);
      
      console.log(error);
    }
  }
  };

  searchStud();
 }
 function getFees(courseID,studID,studYear,schoolYear){
  
  const getfeesStud = async () => {
    
    try {
      const response = await fetch('https://api.sncoi.edu.ph/getfeesperstudent.php?token='+{token}+'&tokenID='+tokenID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({courseID,studID,studYear,schoolYear}),
      });
      
       const data = await response.text();
       //console.log(data);
      const datares=JSON.parse(data);
      setData(datares);
      //setinitial();
      setLoading(false);
    } catch (error) {
      console.error('Error getting data:', error);
      
      console.log(error);
    }
  };

  getfeesStud();
 }
  
  const handlesubmit= async (e)=>{
      e.preventDefault();
      
      if(studID==''){
        Swal.fire({
          title: "Opps..",
          text: "Student required",
          icon: "warning"
        });
        setSubmitting(false);
        return;
        }
      if(schoolYear==''){
        Swal.fire({
          title: "Opps..",
          text: "School year is required",
          icon: "error"
        });
        setSubmitting(false); 
        return;
        }
     
      const sendData = async () => {
        setSubmitting(true);
         
        try {
          const response = await fetch('https://api.sncoi.edu.ph/addnewpayment.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({formData,studID,unitsNumber,schoolYear,tokenID,token,studYear}),
          });
        
          const data = await response.text();
          console.log(data);
          setSubmitting(false);
          let res=JSON.parse(data);
          if(res[0]==1){
            Swal.fire({
              title: "Nice!",
              text: "Fee has been saved",
              icon: "success"
            });
            setTransaction(res[2]);
            setTransactionshow(true);
            setSubmitting(false);
          }else if(res[0]=='dup'){
            Swal.fire({
              title: "Oppss",
              text: res[1] +" Duplicate records ",
              icon: "warning"
            });
           
            setSubmitting(false);
          }else{
            Swal.fire({
              title: "Opps..",
              text: "Something went wrong "+data,
              icon: "error"
            });
            setSubmitting(false);
          }
         
        } catch (error) {
          console.error('Error sending data:', error);
          setSubmitting(false);
          console.log(error);
        }
      };
  
      sendData();

    }
  
  
  useEffect(() => {
     
  
      // Get token from cookie
      let token = getCookie('token');
      let tokenID = getCookie('tokenID');
      if (token) {
          fetch('https://api.sncoi.edu.ph/index.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
            })
            .then(response => response.json())
            .then(data => {
             
              if(data==1){

              }else{
                  navigate('/login');
              }
              
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error (e.g., display error message)
            });
        
      }else{
          navigate('/login');
      }

    }, []);
         
    return(
        <div className="wrapper">
        <TopNav/>

<aside className="main-sidebar sidebar-dark-primary elevation-4">



<div className="sidebar vh-100">
  
  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
    <div className="image">
    <img src='att3.jpg' style={{maxHeight:200,borderRadius:50}}/>
    </div>
    <div className="info">
      <p className="d-block text-light">Finance Admin</p>
    </div>
  </div>


  <SideNav/>

</div>  

</aside>
<div className="content-wrapper">
<section className="content">
  <div className="container-fluid">
     <div className="row">
      <div className="col-12 pt-2">
      <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 550 }}
    >
      <select name="schoolyear" onChange={handleSchoolyear} className="form-control col-5"> 
                <option value="">select schoolyear</option>
                      {sydata.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.AC} - sem {item.sem} 
                        </option>
                         
                      ))}
                   
                </select>
      <InputBase
        value={searchstudent}
        onChange={handlesearchStud}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Student by ID number"
        
        inputProps={{ 'aria-label': 'Search Student by ID number' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={search}>
        <SearchIcon />{searching? (<i className="fa fa-spinner fa-spin"></i>):""}
      </IconButton>
      
    </Paper>
        
      </div>
        <div className="col-4  mt-3">
    
                
                <label>Student ID</label>
                <input type='text' name="studID" value={studID} className="form-control" readOnly />
                <label>Student</label>
                <input type='text' name="studname" value={studName} className="form-control" readOnly/>
                <label>Course</label>
                <input type='text' name="studcourse" value={studCourse} className="form-control" readOnly/>
                <label>Level</label>
                <input type='text' name="studyear" value={studYear} className="form-control" readOnly/>
                
                <label>Units Earned</label>
                <input  type="text" name="unitsnumber" id="unitsnumber" value={unitsNumber} onChange={handleUnits} className="form-control"/>
                <label>Discount% for full payment</label>
                <input  type="text" name="discount" id="discount"  onChange={handleInputChange} className="form-control"/>
                <label>Discount% for tuition</label>
                <input  type="text" name="tuitiondiscount" id="tuitiondiscount"  onChange={handleInputChange} className="form-control"/>
                <label>Scholarship</label>
                <input  type="text" name="scholarship" id="scholarship"  onChange={handleInputChange} className="form-control"/>
                <label>Old Account</label>
                <input  type="text" name="oldaccount" id="oldaccount"  onChange={handleInputChange} className="form-control"/>
        </div>
        <div className="col-8 mt-3">
                 
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <label>Tuition</label>
                <input type='text' name="tuition"  onChange={handleInputChange} className="form-control"/>       
                <label>Registration</label>
                <input type='text' name="registration"  onChange={handleInputChange} className="form-control"/>     
                <label>Misccellaneous</label>
                <input type='text' name="misc" onChange={handleInputChange} className="form-control"/> 
                <label>Advisory</label>
                <input type='text' name="advisory" onChange={handleInputChange} className="form-control"/>
                <label>Crim Lab</label>
                <input type='text' name="crimlab"  onChange={handleInputChange} className="form-control"/>
                <label>SAS Fee</label>
                <input type='text' name="sasfee" onChange={handleInputChange} className="form-control"/>
                <label>Enhancement</label>
                <input type='text' name="enhancement"  onChange={handleInputChange} className="form-control"/>
                <label>USC Membership</label>
                <input type='text' name="uscmembership"  onChange={handleInputChange} className="form-control"/>
                <label>Sholarship Type</label>
               
                <select className="form-control" onChange={handleInputChange} name="stype">
                      <option>select type</option>
                      <option value="1" key="1"> TES</option>  
                      <option value="2" key="2"> TDP</option>  
                      <option value="3" key="3"> CMSP</option>
                      <option value="4" key="4"> SSS</option>   
                      <option value="5" key="5"> GSIS</option>   
                      <option value="6" key="6"> OWWA</option>  
                      <option value="7" key="7"> WORKING SCHOLAR</option> 
                      <option value="8" key="8"> TZUCHI</option>
                      <option value="9" key="9"> SMART</option>     
                      <option value="10" key="10"> LGU-ORMOC</option>
                      <option value="11" key="11"> LGU-CAPOOCAN</option> 
                      <option value="12" key="12"> DOST</option>  
                      <option value="13" key="13"> COCONUT</option>     
                </select>    
                {/* { loading? (<div>No Student Selected..</div>):
                  
                data.map((item) => (
                  <>
                  <br/> <input type="text" key={item.id} id={item.fee_description} name={item.id} onChange={handleInputChange}  value={formData[item.id] || ''} />
                  <label>{item.fee_description} (Amount: {item.amount})</label>
                 </>
                ))
              
                
              } */}
                
            </div>
            {!loading && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={handlesubmit}
                  disabled={submitting}
                >
                  Save {submitting && <i className="fa fa-spinner fa-spin"></i>}
                </button>
              )}
               
           
        </div>
        
      </div>
     
  </div>
</section>
</div>
<aside className="control-sidebar control-sidebar-dark">

</aside>
<footer className="main-footer">
<strong>Copyright &copy; 2024 </strong>
All rights reserved.

</footer>
    </div>
    );
}
export default Studentfees;