
import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Login(){
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });
    const [alertText, setAlertText] = useState('');
    
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value,
        });
    };
    useEffect(() => {
        // Get cookie by name
        const getCookie = (name) => {
          const value = "; " + document.cookie;
          const parts = value.split("; " + name + "=");
          if (parts.length === 2) return parts.pop().split(";").shift();
        };
    
        // Get token from cookie
        const token = getCookie('token');
        const tokenID = getCookie('tokenID');
        if (token) {
            fetch('https://api.sncoi.edu.ph/index.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tokenID,token }) // Send tokenID in request body
              })
              .then(response => response.json())
              .then(data => {
                
                if(data==1){
                    navigate('/admin');
                }
                
              })
              .catch(error => {
                console.error('Error:', error);
                // Handle error (e.g., display error message)
              });
          
        }

      }, []);
      

    function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
      }
      
    const handleSubmit = async (e) => {
        e.preventDefault();

       
            const fetchData = async () => {
              console.log(JSON.stringify(credentials));
              try {
                const response = await fetch('https://api.sncoi.edu.ph/login.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(credentials),
                });
               
                const data = await response.text();
                console.log(data);
                let res=JSON.parse(data);
                if(res[0]==1){
                    setCookie("token", res[1], 7);
                    setCookie("tokenID", res[2], 7);
                    console.log("success");
                    navigate('/admin');
                }else{
                    console.log('Error logging in:');
                    setAlertText("Invalid Credentials");
                }
               
              } catch (error) {
                console.error('Error logging in:', error);
                setError('Error logging in');
              }
            };
        
            fetchData();
         
    };

    return (
        <main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="att3.jpg" alt="login" class="login-card-img" />
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <div class="brand-wrapper">
               
              </div>
             
              <p class="login-card-description">Sign into your account</p>
              <div>
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="email" class="sr-only">Email</label>
                    <input type="email" onChange={handleChange} name="email" id="email" class="form-control" placeholder="Email address" />
                  </div>
                  <div class="form-group mb-4">
                    <label for="password" class="sr-only">Password</label>
                    <input type="password" onChange={handleChange} name="password" id="password" class="form-control" placeholder="***********" />
                  </div>
                  <div class="alert" id="alert">{alertText}</div>
                  <button name="login"  id="login" class="btn btn-block login-btn mb-4">Login <i id="spinner"></i></button>
                  </form>
              </div>
                
                
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </main>
      );
}
export default Login