import React from "react";
import { useNavigate } from 'react-router-dom';
function TopNav(){
    const navigate = useNavigate();
    const handeLogout=()=>{
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'tokenID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

            // Redirect to login page
            navigate('/login');
      }
return(
    <nav className="main-header navbar navbar-expand navbar-dark">
  
            <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
            </li>
            
            </ul>
            <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
                <a className="nav-link" onClick={handeLogout}>
                    <i className="fas fa-power-off"></i>
                
                </a>
                
            </li>
            
            </ul>
            </nav>
);
}
export default TopNav;